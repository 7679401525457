<template>

<div class="tags" v-if="tags.length > 1">

	<div class="tags-prefix">Type</div>

	<div class="tags-item" :class="{'is-active': !value.length}" v-on:click="onClick(0)">All</div>
	<div class="tags-item" :class="{'is-active': $_.contains(value, tag)}" v-for="(tag, index) in tags" :key="index" v-on:click="onClick(tag)">{{ labels[tag] }}</div>

</div>

</template>

<script>

export default {

	props: ['tags', 'labels', 'value'],

	methods: {

		onClick: function(id) {

			id = parseInt(id)
			var selected = this.$_.clone(this.value)

			if (id) {

				if (this.$_.contains(selected, id)) {

					selected.splice(selected.indexOf(id), 1)

				} else {

					selected.push(id)

				}

			} else {

				selected = []

			}

			this.$emit('input', selected)

		}

	}

}

</script>

<style scoped>

.tags {
	display: flex;
	user-select: none;
	flex-wrap: wrap;
}

.is-device .tags {
	justify-content: center;
}

.tags-prefix {
	flex-grow: 1;
	line-height: 46px;
	color: #1a234c;
	font-size: 12px;
	letter-spacing: 1.2px;
	text-transform: uppercase;
}

.is-tablet .tags-prefix {
	display: none;
}

.is-mobile .tags-prefix {
	display: none;
}

.tags-item {
	flex-shrink: 0;
	border-radius: 23px;
	height: 46px;
	padding: 0px 20px;
	line-height: 46px;
	font-size: 12px;
	color: #1a234c;
	text-transform: uppercase;
	background-color: #f5f5f5;
	margin-left: 10px;
	margin-bottom: 5px;
	cursor: pointer;
}

.is-mobile .tags-item {
	height: 32px;
	padding: 0px 10px;
	line-height: 32px;
	margin: 2px;
}

.tags-item.is-active {
	color: #fff;
	background-color: #1277d4;
}

</style>
